import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { FaInstagram, FaYelp } from "react-icons/fa";
import logo from "../images/calinailslogo.png";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg="black"
      color="white"
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      target="_blank"
      transition={"background 0.3s ease"}
      _hover={{
        bg: "pink.200",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg="brand.100"
      paddingTop={24}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Image src={logo} boxSize={"150px"}></Image>
        <Text color="black">© 2025 California Nails. All rights reserved</Text>
        <Stack direction={"row"} spacing={6}>
          <SocialButton
            label={"Yelp"}
            href={"https://www.yelp.com/biz/california-nails-lincoln-3/"}
          >
            <FaYelp />
          </SocialButton>
          <SocialButton
            label={"Instagram"}
            href={"https://www.instagram.com/california.nails.lincoln/"}
          >
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
